import { create } from "zustand";

interface IMenu {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const useMenu = create<IMenu>()(
  (set: (arg0: (state: any) => { open: boolean }) => any) => ({
    open: false,
    setOpen: (isOpen: boolean) => set((state) => ({ open: isOpen })),
  })
);
