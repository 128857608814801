import type { ProFormInstance } from "@ant-design/pro-components";
import { ProCard, StepsForm } from "@ant-design/pro-components";
import { message, Modal } from "antd";
import classNames from "classnames";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import classes from "./Local.module.scss";
import { StepButtons } from "../StepsButtons";

import emailjs from "@emailjs/browser";
import validator from "validator";

import {
  StepFormItemOne,
  StepFormItemThree,
  StepFormItemFour,
  StepFormItemTwo,
} from "../StepForm";
import { IFormData, useFormDataStore } from "../../../utils/store/formData";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

type Email = {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  comment: string;
  transport_from: string;
  transport_to: string;
  vehicle_runs: boolean | string;
  make: string;
  model: string;
  year: string;
  transportation_type: string | boolean;
  estimated_ship_date: string;
};

export default function LocalForm() {
  const formRef = useRef<ProFormInstance & HTMLFormElement>(null);
  const { formData, setFormData } = useFormDataStore();
  const navigate = useNavigate();

  const [modal, contextHolder] = Modal.useModal();

  let [current, setCurrent] = useState<number>(1);

  useEffect(() => {
    if (
      !formData.information.transport_from ||
      !formData.information.transport_to
    ) {
      navigate("/");
    }
  }, [formData]);

  const countDown = () => {
    let secondsToGo = 5;

    let SuccessRedirectModal = modal.success({
      title: "Your Quota successfully sent.",
      content: `We will contact with you. This page will be closed after ${secondsToGo}`,
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      SuccessRedirectModal.update({
        content: `We will contact with you. This page will be closed after ${secondsToGo}`,
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      SuccessRedirectModal.destroy();
      navigate("/");
    }, secondsToGo * 1000);
  };

  function SendEmail(data: any) {
    let date = formData.shipping.estimated_ship_date;

    const Letter: Email = {
      ...data,
      estimated_ship_date: `${date?.$d?.getMonth() + 1}-${date?.$D}-${
        date?.$y
      }`,
      ...formData?.information, // datada bu kelmayapti
      year: data?.year.label,
      model: data.model.label,
      make: data.make.label,
    };
    console.log(Letter, "EMAIL");

    if (formRef.current) {
      emailjs
        .send(
          "service_tf6s8h8",
          "template_84g5ldd",
          Letter,
          "kabFT-o3-2D-eWtT2"
        )
        .then(
          (result) => {
            console.log(result);

            countDown();
          },
          (error) => {
            console.log(error);
            message.error("Error");
          }
        );
    }
  }

  async function Submit(data: any, isCurrent: boolean = true) {
    await formRef.current?.validateFields();
    setFormData({ ...formData, ...data });

    isCurrent && setCurrent(++current);

    await waitTime(1000);
    // return true;
  }

  return (
    <ProCard
      style={{
        minWidth: "100px",
      }}
      className={classNames("container", classes.local)}
    >
      {contextHolder}
      <StepsForm<{
        name: string;
      }>
        current={current}
        formRef={formRef}
        onFinish={async (value) => {
          console.log(value, "MMMMMMMMMMMMMMMMMMM");
          console.log(
            validator.isMobilePhone(formData.contact?.phone, ["en-US"])
          );
          SendEmail(value as any);

          formRef.current?.validateFields();
          await waitTime(1000);
          // message.success("Successful");
        }}
        formProps={{
          validateMessages: {
            required: "This field is required",
          },
        }}
        submitter={{
          render: (e) => {
            return (
              <StepButtons current={current} formElement={e} set={setCurrent} />
            );
          },
        }}
      >
        <StepFormItemOne
          type="local"
          className={classes.step}
          onFinish={async () =>
            await Submit({
              information: formRef.current?.getFieldsValue(),
            })
          }
          statesData={[
            {
              value: "NY",
              label: "New York",
              zip: 123123,
            },
          ]}
        />
        <StepFormItemTwo
          className={classNames(classes.step, classes.step__center)}
          type="local"
          onFinish={async () =>
            await Submit({
              shipping: formRef.current?.getFieldsValue(),
            })
          }
        />
        <StepFormItemThree
          vehicleClass={classNames(classes.step__vehicle)}
          className={classNames(classes.step, classes.step__vertical)}
          type="local"
          onFinish={async () =>
            await Submit({
              vehicles: formRef.current?.getFieldsValue(),
            })
          }
        />
        <StepFormItemFour
          information={formData}
          className={classNames(classes.step, classes.step__center)}
          wrapClass={classes.wrap}
          resultCardClass={classes.resultCard}
          formClass={classes.formClass}
          onFinish={async () =>
            await Submit(
              {
                contact: formRef.current?.getFieldsValue(),
              },
              false
            )
          }
          type="local"
        />
      </StepsForm>
    </ProCard>
  );
}
