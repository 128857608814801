const phone = "+1 (937) 741-3780";
const email = "Info@autoshipez.com";
const location = `
201 E. Fifth Street \n

Suite 1900-1302 \n

Cincinnati, OH  45202
`;

export { phone, email  , location};
