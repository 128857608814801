import { ResultCard } from "./ResultCard/ResultCard";
import classes from "./ResultCards.module.scss";
type Props = {
  data: ResultCardProps[];
};

export const ResultCards: React.FC<Props> = ({ data }) => {
  return (
    <div className={classes.cards}>
      {data?.map((item, index) => (
        <ResultCard {...item} key={index} />
      ))}
    </div>
  );
};
