import React from "react";
import styles from "./ServiceCard.module.scss";

type Props = {
  title: string;
  description: string;
  img: any;
};

export default function ServiceCard({ title, description, img }: Props) {
  return (
    <div className={styles.card}>
      <img src={img} alt="" />
      <div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}
