import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";

type Props = {
  children: React.ReactNode;
};

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <main >{children}</main>
      <Footer/>
    </>
  );
};
