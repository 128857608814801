import React, { useState, useRef, useEffect } from "react";
import { Button, message } from "antd";
import { ReactComponent as MailImage } from "../../../images/mail.svg";
import classes from "./form.module.scss";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useCookies } from "react-cookie";

type FormValues = {
  user_name: string;
  user_email: string;
  user_message: string;
};

export const CustomForm: React.FC = () => {
  const [cookies, setCookie] = useCookies([
    "message-sended",
    "all-messages-sended-date",
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [people, setPeople] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getMinutes();
    currentDate.setMinutes(currentHour + 1);

    if (cookies["message-sended"] === 3) {
      setCookie("all-messages-sended-date", true, {
        expires: currentDate,
      });
      setCookie("message-sended", cookies["message-sended"], {
        expires: currentDate,
      });
    }
    if (!cookies["message-sended"]) {
      setCookie("message-sended", 0);
    }
    if (!cookies["all-messages-sended-date"]) {
      setCookie("all-messages-sended-date", false);
    }
    console.log(cookies["all-messages-sended-date"]);
  }, [cookies, setCookie]);

  const onSubmit = (e: any) => {
    setLoading(() => true);
    if (formRef.current ) {
      if (!cookies["all-messages-sended-date"]) {
        emailjs
          .sendForm(
            "service_vxr63bk",
            "template_iev64j3", //template_uy59696
            formRef.current,
            "TMTJsljJrgQEYvZQu"
          )
          .then(
            (result) => {
              setCookie("message-sended", cookies["message-sended"] + 1);
              message.success("Successful");
              setLoading(() => false);
              reset();
            },
            (error) => {
              message.error("Error");
              setLoading(() => false);
            }
          );
      } else {
        message.info(
          `You sent messages ${cookies["message-sended"] || ""} times`
        );
      }
    }
  };

  return (
    <form
      className={classes.form}
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classes.inputWrapper}>
        <label
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <MailImage fill="none" />
          <b>Name</b>
        </label>
        <input
          style={{ height: "50px " }}
          placeholder={"Your name here"}
          {...register("user_name", {
            required: true,
            minLength: 1,
          })}
        />
        {errors?.user_name && (
          <p className={classes.error}>Please enter your name</p>
        )}
      </div>
      <div className={classes.inputWrapper}>
        <label
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <MailImage fill="none" />
          <b>Email</b>
        </label>
        <input
          style={{ height: "50px " }}
          placeholder={"Your email here"}
          {...register("user_email", {
            required: true,
          })}
        />
        {errors?.user_email ? (
          <p className={classes.error}>Please enter your email</p>
        ) : null}
      </div>

      <div className={classes.inputWrapper}>
        <label
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <MailImage />
          <b>Message</b>
        </label>
        <textarea
          placeholder={"Your message here"}
          style={{ minHeight: "200px", minWidth: "100%", maxWidth: "100%" }}
          {...register("user_message", {
            required: true,
          })}
        />
        {errors?.user_message ? (
          <p className={classes.error}>Please enter your message</p>
        ) : null}
      </div>
      <div className={classes.recaptchaWrapper}>
        {/* <ReCAPTCHA
          sitekey="6LeSY74nAAAAAKhQP3poRfUcmbOkjnGKOXX-f7Oo"
          onChange={() => setPeople(() => true)}
          style={{ width: "100px !important" }}
        />
        {!people ? <p className={classes.error}>Please enter </p> : null} */}
      </div>
      <Button
        type="primary"
        htmlType="submit"
        style={{
          width: "150px",
          height: "50px",
          background: "#fff",
          color: "#fff",
        }}
        color="red"
        loading={loading}
        disabled={loading}
      >
        SEND
      </Button>
    </form>
  );
};
