import {
  ProForm,
  ProFormText,
  ProFormTextArea,
  StepFormProps,
  StepsForm,
} from "@ant-design/pro-components";
import classes from "./StepFormItem.module.scss";

import classNames from "classnames";

import { InputMask } from "primereact/inputmask";

type Props = {
  type: "local" | "international";
  wrapClass: string;
  information?: any;
  resultCardClass?: any;
  formClass?: string;
} & StepFormProps;

export default function StepFormItemFour({
  wrapClass,
  information,
  resultCardClass,
  formClass,
  ...props
}: Props) {
  let date = information.shipping.estimated_ship_date;

  return (
    <StepsForm.StepForm name="nimadir" title="Contact" {...props}>
      <div className={classNames(formClass, classes.form)}>
        <div className={classNames(wrapClass, classes.wrap)}>
          <ProFormText
            className={classes.input}
            rules={[
              {
                required: true,
                type: "string",
              },
            ]}
            name={"firstname"}
            label="First name"
          />
          <ProFormText
            className={classes.input}
            rules={[
              {
                type: "string",
                required: true,
              },
            ]}
            name={"lastname"}
            label="Last name"
          />
        </div>
        <div className={classNames(wrapClass, classes.wrap)}>
          <ProForm.Item
            label="Phone"
            required
            rules={[
              {
                required: true,
                message: "This filed is required",
              },
            ]}
            name={"mask_number"}
            style={{}}
          >
            <InputMask
              width={"auto"}
              // style={{
              //   minWidth: "200px",
              // }}
              mask="+1 (999) 999-9999"
              placeholder="+1 (999) 999-9999"
              className={classes.mask_input}
              required
            />
          </ProForm.Item>

          <ProFormText
            className={classes.input}
            rules={[
              {
                required: true,
                type: "email",
                validateTrigger: "",
              },
            ]}
            name={"email"}
            label="Email"
          />
        </div>
        <div>
          <ProFormTextArea
            // width={""}
            rules={[
              {
                required: false,
                type: "string",
              },
            ]}
            name={"comment"}
            label="Commentac"
            style={{
              // resize: "none",
              width: "100%",
            }}
          />
        </div>
      </div>
      <div className={classNames(resultCardClass, classes.table)}>
        <ul>
          <li>
            <span>From:</span> {information.information.transport_from}
          </li>
          <li>
            <span>To:</span> {information.information.transport_to}
          </li>
          <li>
            <span>Transportation type:</span>
            {information.shipping.transportation_type}
          </li>
          <li>
            <span>Ship date:</span>
            {`${date?.$d?.getMonth() + 1}-${date?.$D}-${date?.$y}`}
          </li>
          <li>
            <span>Year:</span> {information?.vehicles?.year?.label}
          </li>
          <li>
            <span>Make:</span> {information.vehicles.make?.label}
          </li>
          <li>
            <span>Model:</span> {information.vehicles.model?.label}
          </li>
        </ul>
      </div>
    </StepsForm.StepForm>
  );
}
