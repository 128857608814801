import { Collapse } from "antd";
import { ReactComponent as Plus } from "../../images/plus.svg";
import { ReactComponent as Minus } from "../../images/minus.svg";
type Props = {
  data: any;
};

export const Accardion: React.FC<Props> = ({ data }) => {
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      expandIcon={({ isActive }) => {
        if (!isActive) {
          return <Plus stroke="white" />;
        } else {
          return <Minus stroke="white" />;
        }
      }}
      style={{ color: "white" }}
      items={data}
    />
  );
};
