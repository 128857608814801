import React from "react";
import classes from "./ServiceCardAni.module.scss";
import Lottie from "lottie-react";
import classNames from "classnames";

type Props = {
  title: string;
  description: string;
  animation: any;
  text_side: "left" | "right";
};

export default function ServiceCardAni({
  animation,
  description,
  title,
  text_side,
}: Props) {
  return (
    <div
      className={classNames(
        classes.card,
        text_side === "right" && classes.card_right
      )}
    >
      <div className={classes.card__text}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className={classes.card__animation}>
        <Lottie animationData={animation} />
      </div>
    </div>
  );
}
