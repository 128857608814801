import { StepFormProps, StepsForm } from "@ant-design/pro-components";
import { Form, Select as SimpleSelect, Space } from "antd";
import { useEffect, useState } from "react";
import classes from "./StepFormItem.module.scss";
import { useFormDataStore } from "../../../../utils/store/formData";

import useDebounce from "../../../../hooks/useDebounce";

type Props = {
  type: "local" | "international";
  statesData?: any;
} & StepFormProps;

export default function StepFormItemOne({ type, statesData, ...props }: Props) {
  const { formData, setFormData } = useFormDataStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState({
    to: "a",
    from: "a",
  });
  const searchFromValue = useDebounce(searchValue.from, 500);
  const searchToValue = useDebounce(searchValue.to, 500);

  useEffect(() => {
    console.log("rendered");
  });

  const [data, setData] = useState<IPlace[]>([]);

  useEffect(() => {
    fetch(
      `https://truck-api-kappa.vercel.app/api/no-countries/${
        !searchFromValue ? "1" : searchFromValue
      }`,
      {}
    )
      .then((res) => res.json())
      .then((data) => {
        setData(
          data.map((i: IPlace) => ({
            ...i,
            label: i.label,
            key: i.zip,
          }))
        );
      });
  }, [searchFromValue]);

  useEffect(() => {
    fetch(
      `https://truck-api-kappa.vercel.app/api/places/${
        !searchToValue ? "1" : searchToValue
      }`,
      {}
    )
      .then((res) => res.json())
      .then((data) => {
        setData(
          data.map((i: IPlace) => ({
            ...i,
            label: i.label,
            key: i.zip,
          }))
        );
      });
  }, [searchToValue]);

  // if (!countriesData) {
  //   return <Loading />;
  // }

  return (
    <StepsForm.StepForm name="base" title="Information" {...props}>
      <Space wrap>
        <Form.Item
          label="Transport FROM"
          className={classes.form__item}
          name={"transport_from"}
          rules={[
            {
              message: "This field is required",
              required: true,
            },
          ]}
          initialValue={formData.information.transport_from}
        >
          <SimpleSelect
            allowClear
            placeholder="Zip or City"
            filterOption={(input, option) => {
              return (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            style={{
              minWidth: "180px",
            }}
            showSearch
            loading={isLoading}
            options={data}
            onFocus={() =>
              setSearchValue({
                ...searchValue,
                from: "a",
              })
            }
            onSearch={(value) =>
              setSearchValue({
                ...searchValue,
                from: value,
              })
            }
          />
        </Form.Item>
        <div className={classes.line}></div>
        <Form.Item
          label="Transport TO"
          className={classes.form__item}
          name={"transport_to"}
          rules={[
            {
              message: "This field is required",
              required: true,
            },
          ]}
          initialValue={formData.information.transport_to}
        >
          <SimpleSelect
            allowClear
            onFocus={() =>
              setSearchValue({
                ...searchValue,
                from: "a",
              })
            }
            onSearch={(value) => {
              setSearchValue({
                ...searchValue,
                to: value,
              });
            }}
            filterOption={(input, option) => {
              return (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            style={{
              minWidth: "180px",
            }}
            showSearch
            onChange={(e) => console.log(e, "change")}
            placeholder="Zip, City or Country"
            options={data}
          />
        </Form.Item>
      </Space>
    </StepsForm.StepForm>
  );
}
