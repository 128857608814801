

export const headerLinks: HeaderLinks[] = [
  {
    title: "Home",
    href: "#home",
  },
  {
    title: "Steps",
    href: "#steps",
  },
  {
    title: "Services",
    href: "#services",
  },
  {
    title: "FAQ",
    href: "#faq",
  },
  {
    title: "Quotes",
    href: "#quotes",
  },
  {
    title: "About us",
    href: "#about-us",
  },
  {
    title: "Contacts",
    href: "#contacts",
  },
];
