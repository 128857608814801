import "./Sidebar.scss";
import { Props as BurgerProps, push as Menu } from "react-burger-menu";
import { headerLinks } from "./headerLinks";
import { Anchor } from "antd";
import XImage from "../../../images/x.svg";
import React from "react";
import { useMenu } from "../../../utils/store/menu";
type Props = {
  data: HeaderLinks[];
};

export const Sidebar: React.FC<BurgerProps & Props> = ({ data, ...props }) => {
  const { open, setOpen } = useMenu();

  return (
    <Menu
      right
      {...props}
      customCrossIcon={<img src={XImage} alt="X" />}
      isOpen={open}
      onClose={() => setOpen(false)}
      width={300}
    >
      <Anchor
        direction="vertical"
        items={headerLinks?.map((item, index) => ({
          ...item,
          key: `#${item.href}-${index + 1}`,
          className: "menu-item",
        }))}
        onClick={() => setOpen(false)}
        key={"vertical"}
      />
    </Menu>
  );
};
