import classNames from "classnames";
import classes from "./TermsAndConditions.module.scss";

const texts: string[] = [
  ` Carrier and driver jointly and separately are authorized to operate and transport their motor vehicle between its pick-up location and the estimation set forth on the auto transport order contract.`,
  ` Trucking damage claims are covered by Carrier from $100,000 up to $250,000 cargo insurance per load, and a minimum of 3/4 of a million dollars public liability and property damage. All claims must be noted and signed for at the time of delivery, and submitted in writing within 15 calendar days of delivery. EZ Auto Ship LLC shall not be liable and shall be held harmless by Shipper for the performance or nonperformance by Carrier, including but not limited to negligence or gross negligence. `,
  ` LUGGAGE and personal property must be confined to the trunk, with no heavy articles, and not to exceed 100 lbs. Carrier is not liable for damage caused to vehicle from excessive or improper loading of personal items. `,
  `Pickup and delivery is from your door unless residential area restrictions apply. Pickups by appointment are available for an additional fee, by smaller unrestricted auto carriers. If your vehicle is inoperable or oversize (longer than 16 feet, dual wheels, large pick up or van, racks, etc.), customer shall inquire extra charges. If carrier is not advised of inoperable or over-sized vehicles prior to pick up, all extra charges must be paid in cash or certified check on delivery.`,
  `
  Vehicles must be tendered to Carrier in good running condition with no more than a half tank of fuel. Carrier shall not be liable for damage caused by leaking fluids, freezing, exhaust systems, or antennas not tied down. Any claim for loss or damage must be noted and signed on the condition report at the time of delivery. Any claims by Shipper shall be exclusively filed against the Carrier.
    `,
  `
  If Carrier is sent out and the vehicle isn’t ready as indicated by Shipper there shall be an additional $95.00 rescheduling fee. EZ Auto Ship LLC must be notified at least 48 hours in advance, should Shipper be unavailable for pick up or delivery, Shipper must have an alternate representative take their place as Shipper. If for any reason the vehicle becomes unavailable during a scheduled pick-up window, after an order has been placed, EZ Auto Ship LLC has the right to cancel the order with no refund.
    `,
  `
  EZ Auto Ship LLC agrees to provide a carrier to transport your vehicle as promptly as possible in accordance with your instructions but cannot guarantee pick-up or delivery on a specified date. An administration fee of $200 shall be charged for orders canceled prior to one week after the requested initial available pickup date. EZ Auto Ship LLC does not agree to pay for your rental of a vehicle, nor shall it be liable for the failure of mechanical or operating parts of your vehicle.
    `,
  `
  No electronic equipment, valuables, plants, live pets, alcohol, drugs or firearms, may be left in the vehicle.
    `,
  `
  . A $150.00 non-operational fee shall be charged for all non-running vehicles. This shall be included in the final quote received from EZ Auto Ship LLC. If the vehicle becomes non-operational during transport, this fee shall be applied to the original quote.
    `,
  `Shipper warrants that they shall pay the price quoted due to EZ Auto Ship LLC for delivered vehicles, and shall not seek to charge back a credit card or stop a check to offset any dispute for damage claims. This agreement and any shipment hereunder are subject to all terms and conditions of the Carrier’s tariff and the uniform straight bill of lading, copies of which are available at the office of Carrier. Department of Transportation regulations require that all claims be filed in writing and all tariffs be paid in full before claim be processed.
    `,
];

export const TermsAndConditions: React.FC = () => {
  return (
    <div className={classes.TermsAndConditions}>
      <div className={classNames(classes.container, "container")}>
        <div className={classes.content}>
          <h1 className={classes.content__title}>TERMS AND CONDITIONS</h1>
          <span className={classes.content__descriptions}>
            {texts?.map((item, index) => (
              <p
                className={classes.content__descriptions__description}
                key={index}
              >
                <span> {index + 1}</span>. {item}
              </p>
            ))}
            <p>
              EZ Auto Ship LLC U.S. Department of Transportation Broker’s
              license # 1566229. This supersedes all prior written or oral
              representation of EZ Auto Ship LLC and constitutes the entire
              agreement between Shipper and EZ Auto Ship LLC and may not be
              changed except in writing signed by an officer of EZ Auto Ship
              LLC. EZ Auto Ship LLC has the authorization to cancel the order at
              any time.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};
