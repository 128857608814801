import "./App.scss";
import { Home } from "./pages/index";
import { Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import loc from "antd/locale/en_US";
import { TermsAndConditions } from "./pages/TermsAndConditions/TermsAndConditions";
import { Quote } from "./pages/Quote/Quote";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import PrivancPolicy from "./pages/PrivcanyPolicy";

// import { Server } from "miragejs";
// import CountriesData from "./data/countries.json";

// new Server({
//   logging: false,
//   routes() {
//     this.get("/api/countries", () => CountriesData);
//   },
// });

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          locale={loc}
          theme={{
            token: {
              colorPrimary: "#0099ff",
            },
            components: {},
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quote" element={<Quote />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="/privancy-policy"
              element={<PrivancPolicy />}
            />
          </Routes>
        </ConfigProvider>
      </QueryClientProvider>
    </div>
  );
}
export default App;
