import { Text } from "./Text/Text";
import classes from "./index.module.scss";

type Props = {
  data: TextProps[];
  title: string;
};

export const Texts: React.FC<Props> = ({ data, title }) => {
  return (
    <div className={classes.textsWrapper}>
      <h1 className={classes.textsWrapper__heading}>{title}</h1>
      <div className={classes.texts}>
        {data?.map((item, index) => (
          <Text {...item} key={index} />
        ))}
      </div>
    </div>
  );
};
