import { Logo } from "../Logo";
import classes from "./LocationCard.module.scss";
import { ReactComponent as Location } from "../../images/locationIcon.svg";
import { ReactComponent as Phone } from "../../images/phoneIcon.svg";
import { ReactComponent as Email } from "../../images/email.svg";
import { email, location, phone } from "../../utils/configs";
export const LocationCard: React.FC = () => {
  return (
    <div className={classes.locationCard}>
      <div className={classes.locationItems}>
        <div className={classes.locationItems__item}>
          <Logo />
        </div>
        <div className={classes.locationItems__item}>
          <Location />
          <h3 className={classes.locationItems__item__title}>{location}</h3>
        </div>
        <div className={classes.locationItems__item}>
          <Phone />
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
        <div className={classes.locationItems__item}>
          <Email />
          <a href={`mailto:${email}?subject = Hello &body = Message`}>
            {email}
          </a>
        </div>
      </div>
    </div>
  );
};
