import { CountUp } from "use-count-up";
import classes from "./ResultCard.module.scss";
export const ResultCard: React.FC<ResultCardProps> = ({
  description,
  icon,
  title,
}) => {
  return (
    <div className={classes.card}>
      <div className={classes.card__icon}>{icon}</div>
      <div className={classes.text}>
        <h2 className={classes.text__title}>
          <CountUp isCounting  end={title} easing={"linear"} duration={3.2}  />+
        </h2>
        <p className={classes.card__text__description}>{description}</p>
      </div>
      <div className={classes.card__backgroundArrow}></div>
    </div>
  );
};
