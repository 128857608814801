import { Space, Button } from "antd";

type Props = {
  current: number;
  set: Function;
  formElement: any;
};

export default function StepButtons({ current, formElement, set }: Props) {
  return (
    <Space
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {current !== 0 && (
        <Button onClick={() => set(--current)}>Previous</Button>
      )}
      {current === 3 ? (
        <Button
          htmlType="submit"
          type="primary"
          onClick={() => formElement.form?.submit()}
        >
          Submit
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() => {
            formElement.form?.submit();
          }}
        >
          Next
        </Button>
      )}
    </Space>
  );
}
