import classes from "./Text.module.scss";

export const Text: React.FC<TextProps> = ({ colorBox, description, title }) => {
  return (
    <div className={classes.text}>
      <div className={classes.text__title}>
        <div
          className={classes.text__title__colorBox}
          style={{ background: colorBox }}
        />
        <h2 className={classes.text__title__text}>{title}</h2>
      </div>
        <p className={classes.text__description}>{description}</p>
    </div>
  );
};
