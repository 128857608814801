import { useEffect, useState } from "react";
import classNames from "classnames";
import classes from "./Header.module.scss";
import { Logo } from "../../Logo/index";
import { Anchor } from "antd";
import { headerLinks } from "./headerLinks";
import menuImage from "../../../images/menu.svg";
import { useMenu } from "../../../utils/store/menu";
import { useLocation } from "react-router";
import { ReactComponent as PhoneIcon } from "../../../images/phoneIcon.svg";
import { phone } from "../../../utils/configs";
export const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const { open, setOpen } = useMenu();
  const url = useLocation().pathname;

  useEffect(() => {
    const controlNavbar = () => {
      if (window && window.scrollY > 10) {
        setIsScrolled(() => true);
      } else {
        setIsScrolled(() => false);
      }
    };
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  return (
    <header
      className={classNames(classes.header, {
        [classes.headerIsScrolled]: isScrolled,
        [classes.notMain]: url !== "/",
      })}
    >
      <div className={classNames(classes.container, "container")}>
        <Logo />
        {url === "/" ? (
          <div className={classes.links}>
            <Anchor
              direction="horizontal"
              items={headerLinks?.map((item, index) => ({
                ...item,
                key: `${item.href}`,
              }))}
              key={"horizontal"}
              targetOffset={150}
              rootClassName={classes.links__anchor}
            />
          </div>
        ) : (
          <div className={classes.phone}>
            <a href={`tel:${phone}`}>
              <PhoneIcon />
              <h2>{phone}</h2>
            </a>
          </div>
        )}
        <div
          className={classNames(classes.burger)}
          onClick={() => setOpen(!open)}
        >
          <img src={menuImage} alt="menu" className={classes.burger__image} />
        </div>
      </div>
    </header>
  );
};
