import classNames from "classnames";
import classes from "./Footer.module.scss";
import { Logo } from "../../Logo";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
  return (
    <footer className={classes.footer}>
      <div className={classNames(classes.container, "container")}>
        <div className={classes.logo}>
          <div className={classes.image}>
            <Logo color="blue" initalSize="200px" />
          </div>
        </div>
        <div className={classes.links}>
          <h3 className={classes.links__link}>
            2019 © Ezautoship All rights reserved.
          </h3>
          <div style={{display : "flex" , gap : "20px"}}>
            <Link to={"/terms-and-conditions/"}>Terms and Conditions</Link>
            <Link to={"/privancy-policy/"}>Privacy Policy</Link>
          </div>
          {/* <h3 className={classes.links__link}>
            developed and hosted by{" "}
            <Link to="https://codeband.uz/" target="_ blank">
              Codeband
            </Link>
          </h3> */}
        </div>
      </div>
    </footer>
  );
};
