import { LocalForm } from "../../components/StepsForm";
import classes from "./Quote.module.scss";
import ShowcaseBackground from "../../images/showcase2.webp";
import classNames from "classnames";
import { Select } from "antd";

export const Quote: React.FC = () => {
  return (
    <div
      className={classNames(classes.quote, "step-form-page")}
      style={{
        backgroundImage: `url(${ShowcaseBackground})`,
      }}
    >
      <LocalForm />
    </div>
  );
};
